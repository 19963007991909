import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
import { authCompanyListData, companyDetailData } from './test-data/03'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.company_base_url)


// 企业数据 - 接口列表
const apis = {
  // 工作台： 企业列表 - 已通过， 已拒绝 ， 待审核
  authCompanyList: '/yijiajia-user-auth-organization/page',
  // 企业详情
  companyDetail: '/yijiajia-user-auth-organization/detail',
  // 企业审核
  updateAuthResult: '/yijiajia-user-auth-organization/updateAuthResult',

  // 数据维护：企业列表 - 已通过审核
  approvedCompanyList: '/yijiajia-company-info/page',
  // 数据维护：新增企业 
  addApprovedCompany: '/yijiajia-company-info/save',
  // 数据维护：企业详情
  approvedCompanyDetail: '/yijiajia-company-info/detail',
  // 数据维护：修改企业信息
  updateCompanyDetail: '/yijiajia-company-info/update',

  // 数据维护：通过公司获取所选模板业务集
  companyBusinessList: '/yijiajia-company-template-property-relation/listByCompanyId',
  // 数据维护：存储或修改企业所选模板属性
  submitCompanyBusiness: '/yijiajia-company-template-property-relation/submit',

  // 数据维护：企业用户列表
  enterpriseUserList:  '/yijiajia-company-info/userList',
  // 数据维护：新增企业用户
  saveEnterpriseUser: '/yijiajia-company-info/saveEnterpriseUser',
  // 数据维护：绑定关系
  bindRelationship: '/yijiajia-company-user/bindRelationship',
  // 数据维护：解除关系
  dismissRelationship: '/yijiajia-company-user/dismissRelationship',

  // 颁发证书
  issueCertificate: '/yijiajia-company-certificate/issueCertificate',
  // 会员企业
  memberCompanyList: '/yijiajia-company-info/pageMemberCompany',

  // 数据模板 - 分组列表
  groupList: '/yijiajia-company-template/groupList',
  // 数据模板 - 属性列表
  propertyList: '/yijiajia-company-template/propertyList',
  // 数据模板 - 属性排序
  propertySort: '/yijiajia-company-template/propertySort',
  // 数据模板 - 删除分组
  removeGroup: '/yijiajia-company-template/removeGroup',
  // 数据模板 - 删除属性
  removeProperty: '/yijiajia-company-template/removeProperty',
  // 数据模板 - 删除模板
  removeTemplate: '/yijiajia-company-template/removeTemplate',
  // 数据模板 - 新增分组
  saveGroup: '/yijiajia-company-template/saveGroup',
  // 数据模板 - 新增属性
  saveProperty: '/yijiajia-company-template/saveProperty',
  // 数据模板 - 新增模板
  saveTemplate: '/yijiajia-company-template/saveTemplate',
  // 数据模板 - 模板列表
  templateList: '/yijiajia-company-template/templateList',
  // 数据模板 - 修改分组
  updateGroup: '/yijiajia-company-template/updateGroup',
  // 数据模板 - 修改属性
  updateProperty: '/yijiajia-company-template/updateProperty',
  // 数据模板 - 修改模板
  updateTemplate: '/yijiajia-company-template/updateTemplate',
}

// 企业列表
// export function authCompanyList (parameter) {
//   return request({
//     url: apis.authCompanyList,
//     method: 'get',
//     params: parameter
//   })
// }
export function authCompanyList(parameter) {
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve(authCompanyListData)
      }, 300)
  })
}


// 企业详情
// export function companyDetail (id) {
//   return request({
//     url: apis.companyDetail + '/' + id,
//     method: 'get',
//   })
// }

export function companyDetail(parameter) {
  return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve(companyDetailData)
      }, 300)
  })
}

// 企业审批
export function updateAuthResult (parameter) {
  return request({
    url: apis.updateAuthResult,
    method: 'post',
    data: parameter
  })
}

// 数据维护：企业列表 - 已通过审核
export function approvedCompanyList (parameter) {
  return request({
    url: apis.approvedCompanyList,
    method: 'get',
    params: parameter
  })
}

// 新增企业
export function addApprovedCompany (parameter) {
  return request({
    url: apis.addApprovedCompany,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


// 数据维护：企业详情
export function approvedCompanyDetail (id) {
  return request({
    url: apis.approvedCompanyDetail + '/' + id,
    method: 'get',
  })
}

// 数据维护：修改企业信息
export function updateCompanyDetail (parameter) {
  return request({
    url: apis.updateCompanyDetail,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 数据维护：通过公司获取所选模板业务集
export function companyBusinessList (parameter) {
  return request({
    url: apis.companyBusinessList,
    method: 'get',
    params: parameter
  })
}

// 数据维护：存储或修改企业所选模板属性
export function submitCompanyBusiness (parameter) {
  return request({
    url: apis.submitCompanyBusiness,
    method: 'post',
    data: parameter
  })
}

// 数据维护：企业用户列表
export function enterpriseUserList (parameter) {
  return request({
    url: apis.enterpriseUserList,
    method: 'get',
    params: parameter
  })
}

// 数据维护：新增企业用户
export function saveEnterpriseUser (parameter) {
  return request({
    url: apis.saveEnterpriseUser,
    method: 'post',
    data: parameter
  })
}

// 数据维护：绑定关系
export function bindRelationship (parameter) {
  return request({
    url: apis.bindRelationship,
    method: 'post',
    data: parameter
  })
}

// 数据维护：解除关系
export function dismissRelationship (parameter) {
  return request({
    url: apis.dismissRelationship,
    method: 'post',
    data: parameter
  })
}

// 颁发证书
export function issueCertificate (parameter) {
  return request({
    url: apis.issueCertificate,
    method: 'post',
    data: parameter
  })
}

// 会员企业: 企业列表
export function memberCompanyList (parameter) {
  return request({
    url: apis.memberCompanyList,
    method: 'get',
    params: parameter
  })
}


// 数据模板 - 分组列表
export function groupList (parameter) {
  return request({
    url: apis.groupList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 属性列表
export function propertyList (parameter) {
  return request({
    url: apis.propertyList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 属性排序
export function propertySort (parameter) {
  return request({
    url: apis.propertySort,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 数据模板 - 删除分组
export function removeGroup (parameter) {
  return request({
    url: apis.removeGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 删除属性
export function removeProperty (parameter) {
  return request({
    url: apis.removeProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 删除模板
export function removeTemplate (parameter) {
  return request({
    url: apis.removeTemplate,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增分组
export function saveGroup (parameter) {
  return request({
    url: apis.saveGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增属性
export function saveProperty (parameter) {
  return request({
    url: apis.saveProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 新增模板
export function saveTemplate (parameter) {
  return request({
    url: apis.saveTemplate,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 模板列表
export function templateList (parameter) {
  return request({
    url: apis.templateList,
    method: 'get',
    params: parameter
  })
}
// 数据模板 - 修改分组
export function updateGroup (parameter) {
  return request({
    url: apis.updateGroup,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 修改属性
export function updateProperty (parameter) {
  return request({
    url: apis.updateProperty,
    method: 'post',
    data: parameter
  })
}
// 数据模板 - 修改模板
export function updateTemplate (parameter) {
  return request({
    url: apis.updateTemplate,
    method: 'post',
    data: parameter
  })
}

