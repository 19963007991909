export const authCompanyListData = {
  "code": 200,
  "success": true,
  "data": {
    "current": 1,
    "size": 10,
    "total": 10,
    "pages": 1,
    "records": [
      {
        "authId": 2978,
        "userId": 5491,
        "companyType": 1,
        "companyName": "杭州季遇服饰有限公司",
        "companyLicenseNumber": "91330110MA2B20WEXB",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330108,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "18667028083",
        "companyKeyPerson": "王子辉",
        "companyKeyPersonPhone": "18667028083",
        "companyBrand": "",
        "authUserSubmitTime": "2020-12-29 15:31:59",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2977,
        "userId": 5490,
        "companyType": 1,
        "companyName": "杭州梦栖服装有限公司",
        "companyLicenseNumber": "91330110MA2B13HE7N",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "",
        "companyKeyPerson": "马国峰",
        "companyKeyPersonPhone": "13345817005",
        "companyBrand": "",
        "authUserSubmitTime": "2020-12-29 15:26:53",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2976,
        "userId": 5489,
        "companyType": 1,
        "companyName": "杭州美祺娜服饰贸易有限公司",
        "companyLicenseNumber": "91330110056700970L",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "",
        "companyKeyPerson": "罗权章",
        "companyKeyPersonPhone": "13819153166",
        "companyBrand": "",
        "authUserSubmitTime": "2020-12-29 15:22:16",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2962,
        "userId": 5472,
        "companyType": 1,
        "companyName": "杭州乐甯服饰有限公司",
        "companyLicenseNumber": "91330106MA2B06AY9T",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "15868838085",
        "companyKeyPerson": "欧凤",
        "companyKeyPersonPhone": "15868838085",
        "companyBrand": "老虎牌,狮子牌,兔子牌,小狗牌,乌龟牌,小虫子,小李子,小果子,毛毛",
        "authUserSubmitTime": "2020-10-10 14:59:21",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": 25,
        "authAdminSubmitTime": "2020-10-10 15:04:28",
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2961,
        "userId": 5472,
        "companyType": 1,
        "companyName": "杭州淑影服饰有限公司",
        "companyLicenseNumber": "91330110MA2GK0HD9E",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "15345714418",
        "companyKeyPerson": "申国柱",
        "companyKeyPersonPhone": "13735442618",
        "companyBrand": "老虎牌,狮子牌,兔子牌,小狗牌,乌龟牌,小虫子,小李子,小果子,毛毛",
        "authUserSubmitTime": "2020-10-10 14:56:22",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2960,
        "userId": 5472,
        "companyType": 1,
        "companyName": "杭州于舍服饰有限公司",
        "companyLicenseNumber": "91330110MA2B2MBM13",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "18957117502",
        "companyKeyPerson": "舒静",
        "companyKeyPersonPhone": "18957117502",
        "companyBrand": "老虎牌,狮子牌,兔子牌,小狗牌,乌龟牌,小虫子,小李子,小果子,毛毛",
        "authUserSubmitTime": "2020-10-10 14:53:40",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2956,
        "userId": 5475,
        "companyType": 1,
        "companyName": "杭州赫穗服饰有限公司",
        "companyLicenseNumber": "91330110MA2CD8F34A",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330109,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "0571-86297205",
        "companyKeyPerson": "陈文",
        "companyKeyPersonPhone": "18868410048",
        "companyBrand": "",
        "authUserSubmitTime": "2020-09-23 15:51:50",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2929,
        "userId": 5403,
        "companyType": 1,
        "companyName": "上海速单科技有限公司",
        "companyLicenseNumber": "91310114MA1GUKNQ0W",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "上海上海市徐汇",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "13918185470",
        "companyKeyPerson": "陈丽",
        "companyKeyPersonPhone": "13918185470",
        "companyBrand": "阿迪达斯.中国李宁,美特斯邦威,优衣库,森马",
        "authUserSubmitTime": "2020-04-22 15:46:09",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2928,
        "userId": 5403,
        "companyType": 1,
        "companyName": "杭州米哆西服饰有限公司",
        "companyLicenseNumber": "91330110MA2GNAR85R",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "13968847984",
        "companyKeyPerson": "张春龙",
        "companyKeyPersonPhone": "13968847984",
        "companyBrand": "阿迪达斯.中国李宁,美特斯邦威,优衣库,森马",
        "authUserSubmitTime": "2020-04-22 15:43:37",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "",
        "factoryFullAddress": ""
      },
      {
        "authId": 2927,
        "userId": 5403,
        "companyType": 1,
        "companyName": "桐乡市艾弗艾时装有限公司",
        "companyLicenseNumber": "91330483307315015H",
        "companyLicenseImage": null,
        "companyChargePerson": "",
        "companyChargePersonIdNumber": "",
        "companyProvince": 330000,
        "companyCity": 330100,
        "companyDistrict": 330110,
        "companyAddress": "浙江杭州余杭区",
        "factoryProvince": null,
        "factoryCity": null,
        "factoryDistrict": null,
        "factoryAddress": null,
        "companyPhone": "13958075399",
        "companyKeyPerson": "叶飞",
        "companyKeyPersonPhone": "13958075399",
        "companyBrand": "阿迪达斯.中国李宁,美特斯邦威,优衣库,森马",
        "authUserSubmitTime": "2020-04-22 15:41:24",
        "authResult": "p",
        "authRejectCausation": null,
        "authInfoIsRead": "n",
        "authAdminId": null,
        "authAdminSubmitTime": null,
        "companyFullAddress": "浙江杭州余杭区西湖大道发激将法101号",
        "factoryFullAddress": ""
      }
    ]
  },
  "msg": "操作成功"
}

export const companyDetailData = {
  "code": 200,
  "success": true,
  "data": {
    "authId": 2978,
    "userId": 5491,
    "companyType": 1,
    "companyName": "杭州季遇服饰有限公司",
    "companyLicenseNumber": "91330110MA2B20WEXB",
    "companyLicenseImage": null,
    "companyChargePerson": "",
    "companyChargePersonIdNumber": "",
    "companyProvince": 330000,
    "companyCity": 330100,
    "companyDistrict": 330108,
    "companyAddress": "浙江杭州余杭区",
    "factoryProvince": null,
    "factoryCity": null,
    "factoryDistrict": null,
    "factoryAddress": null,
    "companyPhone": "18667028083",
    "companyKeyPerson": "王子辉",
    "companyKeyPersonPhone": "18667028083",
    "companyBrand": "",
    "authUserSubmitTime": "2020-12-29 15:31:59",
    "authResult": "p",
    "authRejectCausation": null,
    "authInfoIsRead": "n",
    "authAdminId": null,
    "authAdminSubmitTime": null,
    "companyFullAddress": "",
    "factoryFullAddress": ""
  },
  "msg": "操作成功"
}